import { createRouter, createWebHistory } from 'vue-router'

const routes = [
  {
    path: '/',
    component: () => import('@/containers/DefaultContainer'),
    children: [
      {
        path: '',
        name: 'home',
        component: () => import('@/views/HomeView'),
        props: true
      },
      {
        path: 'search',
        name: 'search',
        component: () => import('@/views/SearchView'),
        props: true
      },
      {
        path: 'request/:recordId',
        name: 'request',
        component: () => import('@/views/RequestView'),
        props: true
      },
    ]
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
