import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import CoreuiVue from '@coreui/vue'
import CIcon from '@coreui/icons-vue'
import { iconsSet as icons } from '@/assets/icons'
import Axios from 'axios';

const app = createApp(App)

// Axios
{
  const instance = Axios.create({
    baseURL: '/api/'
  });

  app.config.globalProperties.$http = instance
}

// Filters
app.config.globalProperties.$filters = {
  formatDatetime(value) {
    return value
    // return new Date(value).toLocaleDateString() + new Date(value).toLocaleTimeString()
  }
}

app.use(store)
app.use(router)
app.use(CoreuiVue)
app.provide('icons', icons)
app.component('CIcon', CIcon)
app.mount('#app')